<template>
    <div id="app">
        <div class="container h-100">
            <div id="bar">
                <h1>Vue Email Editor (Demo)</h1>

                <button @:click="saveDesign">Save Design</button>
                <button @:click="exportHtml">Export HTML</button>
            </div>

            <EmailEditor
                ref="emailEditor"
                class="vh-100"
                @:load="editorLoaded"
                @:ready="editorReady"
            />
        </div>
    </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";

export default {
    name: "App",
    components: {
        EmailEditor
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("category:List", "/app");
    },
    methods: {
        // called when the editor is created
        editorLoaded() {
            console.log("editorLoaded");
            // Pass the template JSON here
            // this.$refs.emailEditor.editor.loadDesign({});
        },
        // called when the editor has finished loading
        editorReady() {
            console.log("editorReady");
        },
        saveDesign() {
            this.$refs.emailEditor.editor.saveDesign((design) => {
                console.log("saveDesign", design);
            });
        },
        exportHtml() {
            this.$refs.emailEditor.editor.exportHtml(async (data) => {
                await navigator.clipboard.writeText(data.html);
                console.log("exportHtml", data);
            });
        }
    }
};
</script>
